import { DEFAULT_LANGUAGE } from "./definitions";

const setLocale = (locale: string) => {
  setToLocalStorage("locale", locale);
};

const getLocale = () => {
  if (!getFromLocalStorage("locale")) return DEFAULT_LANGUAGE.toLowerCase();
  return getFromLocalStorage("locale");
};

const setSkin = (skin: string) => {
  setToLocalStorage("skin", skin);
};

const getSkin = () => {
  return getFromLocalStorage("skin");
};

const setSession = (session: any) => {
  setToLocalStorage("session", session);
  if (typeof window !== "undefined") {
    const event = new Event("setSession");
    window.dispatchEvent(event);
  }
};

const getSession = () => {
  return getFromLocalStorage("session");
};

const setOldSession = (session: any) => {
  setToLocalStorage("oldSession", session);
};

const getOldSession = () => {
  return getFromLocalStorage("oldSession");
};

const getLodgingFilters = () => {
  return getFromLocalStorage("lodgingFilters");
};

const setLodgingFilters = (filters) => {
  setToLocalStorage("lodgingFilters", filters);
};

const getInAppPurchase = () => {
  return getFromLocalStorage("inAppPurchase");
};

const setInAppPurchase = (inAppPurchase) => {
  setToLocalStorage("inAppPurchase", inAppPurchase);
};

const setToLocalStorage = (name, settings) => {
  if (typeof window !== "undefined" && window.localStorage) {
    const aux = JSON.stringify(settings);
    localStorage.setItem(name, aux);
  }
};

const getFromLocalStorage = (name) => {
  if (typeof window !== "undefined" && window.localStorage) {
    const item = localStorage.getItem(name);
    if (item) {
      try {
        return JSON.parse(item);
      } catch (e) {
        return item;
      }
    }
  }
};

export {
  setLocale,
  getLocale,
  setSkin,
  getSkin,
  setSession,
  getSession,
  setOldSession,
  getOldSession,
  getLodgingFilters,
  setLodgingFilters,
  getInAppPurchase,
  setInAppPurchase,
};
