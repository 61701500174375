import { getLocale } from "../../utils/localStorage";

import en from "../locales/en.json";
import nb from "../locales/nb.json";
import es from "../locales/es.json";

const locales = {
  en: en,
  nb: nb,
  es: es,
};

interface LocaleAsStringProps {
  id: availableIds;
  replace?: string;
}

type availableIds = keyof typeof en;

const LocaleAsString = ({ id, replace }: LocaleAsStringProps) => {
  const language = getLocale();
  const locale = locales[language];

  let translation = locale[id] || "";
  if (replace) translation = translation.replace("#{VAR}", replace);
  return translation;
};

export default LocaleAsString;
